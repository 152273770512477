import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function List() {
  const [data, setData] = useState(null);
  const [id, setId] = useState(null);
  let [n, setName] = useState('');
  let [m, setMessage] = useState('');
  let name = '';
  let message = '';

  useEffect(() => {
    fetch('https://todo-list-dd12c-default-rtdb.asia-southeast1.firebasedatabase.app/list.json')
      .then(response => response.json())
      .then(json => {
        console.log(json);
        const postArray = [];
        for(const key in json){
            if(json.hasOwnProperty(key)){
                postArray.push({...json[key], id: key})
            }
        }
        postArray.reverse();
        setData(postArray)
      })
      .catch(error => console.error(error));
  }, [id]);

  function deletePost(id){
    console.log("Delete: ", id);
    deleteData(id);
  }

  function postData(){
    //setInputData({name: name, message: message});
    console.log({name: n, message: m});

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: n, message: m })
    };

    fetch('https://todo-list-dd12c-default-rtdb.asia-southeast1.firebasedatabase.app/list.json', requestOptions)
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setName('');
        setMessage('');
        setId(data);
    });
  }

  function deleteData(id){

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };

    fetch('https://todo-list-dd12c-default-rtdb.asia-southeast1.firebasedatabase.app/list/'+id+'.json', requestOptions)
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setId(Math.random());
    });
  }
    
  return (
    <div className="App" class="container">
      <h1>List</h1>
      <input type="text" class="form-control" value={n} placeholder="Enter name" onChange={(e)=>{setName(e.target.value);}}/>
      <textarea class="form-control" value={m} placeholder="Enter message" onChange={(e)=>{setMessage(e.target.value);}}></textarea>
      <div class="d-grid gap-2">
        <Button class="btn btn-primary" onClick={()=>{postData()}}>Add</Button>
      </div>
      <div>
        {
            (data ? data.map(function(d, idx){
                return (
                    <div key={idx} class="border">
                        <h1>{d.name}</h1>
                        <p>{d.message}</p>
                        <button class="btn btn-danger" onClick={()=>{deletePost(d.id)}}>Delete</button>
                    </div>
                )
            }) : 'loading')
        }
      </div>
    </div>
  );
}

export default List;
